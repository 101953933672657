import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { useMediaQuery } from 'usehooks-ts'
import { useDropzone } from 'react-dropzone'

import { Empty, Button, List, message } from 'antd'

import { useSelector, useDispatch } from 'react-redux'

import * as S from './styles'

import { getIsSidebarOpen } from '../../redux/sidebar/reducer'
import { closeSidebar } from '../../redux/sidebar/actions'
import { getTransferOrders } from '../../redux/quantities/reducer'
import { getFilterValueByKey } from '../../redux/filters/reducer'

import { useAuth0 } from '../../auth0-wrapper'

import { useStores } from '../StoresProvider'
import RecapTable from '../RecapTable/RecapTable'
import { getQuantities } from '../../redux/quantities/reducer'

import { addQuantity } from '../../redux/quantities/actions'

import EmailForm from './EmailForm'

import { sendEmail, getEmailFields } from './utility'

import Create from './Create'
import { useProducts } from '../ProductsProvider'

const Sidebar = () => {
  const isSidebarOpen = useSelector(getIsSidebarOpen)
  const stores = useSelector(getFilterValueByKey('store'))
  const warehouseType = useSelector(getFilterValueByKey('warehouseType'))

  const {
    getStoreName,
    getStoreClass,
    getStoreDeliveryDay,
    getStoreEmail,
    getStoreDeliveryTime,
    getStoreShippingCurrier,
    getApparelAdditionalCare,
    isStore,
    getStoreCCEmail,
    getSubsidiary,
    getStoreSublocations,
    getStorePickUpDay,
    getStorePickUpTime,
  } = useStores()

  const to = [
    ...stores,
    ...stores.reduce(
      (acc, s) => [...acc, ...getStoreSublocations(s).map(({ id }) => id)],
      [],
    ),
  ]

  const transferOrders = useSelector(getTransferOrders({ to })).map(order => ({
    ...order,
    requestIds: [],
    items: order.items.map(item => ({
      ...item,
      requestIds: [],
    })),
  }))

  const quantities = useSelector(getQuantities)

  const href = useMemo(() => {
    const file = new Blob([JSON.stringify(quantities, null, 4)], {
      type: 'application/json',
    })
    return URL.createObjectURL(file)
  }, [quantities])

  const [token, setToken] = useState()

  const [done, setDone] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const isTooSmall = useMediaQuery('only screen and (max-width : 1280px')

  const formRef = useRef(null)

  const [emailTransferOrders, setEmailTransferOrders] = useState([])

  const dispatch = useDispatch()

  const onDrop = useCallback(
    acceptedFiles => {
      // Do something with the files

      const file = acceptedFiles[0] // accetto solo un file

      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = e => {
        const quantities = JSON.parse(e.target.result)

        for (let quantity of quantities) {
          dispatch(addQuantity(quantity))
        }

        message.success(
          `TO importato. Potrebbe non apparire nulla nella sidebar, a seconda dello store selezionato`,
        )
      }
      reader.readAsText(file)
    },
    [dispatch],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'application/json, text/json',
    maxFiles: 1,
    noClick: true,
  })

  const { getTokenSilently } = useAuth0()

  const handleCancel = () => {
    setIsVisible(false)
  }

  const { getWarehouseName } = useProducts()

  const handleSubmit = async () => {
    if (!token || emailTransferOrders.length === 0) return

    const { form } = formRef.current

    const { emailBody, emailSubject, cc } = await form.validateFields()

    return sendEmail({
      transferOrders: emailTransferOrders.sort((a, b) =>
        Math.sign(a.warehouseId - b.warehouseId),
      ),
      emailBody,
      emailSubject,
      token,
      cc,
    }).then(() => {
      setDone(true)
    })
  }

  useEffect(() => {
    getTokenSilently().then(token => setToken(token))
  }, [getTokenSilently])

  useEffect(() => {
    if (!done) return

    message.success(`Email sent`)
  }, [done])

  return (
    <S.Drawer
      title="Transfer Order Summaries"
      placement="right"
      closable={true}
      onClose={() => dispatch(closeSidebar())}
      visible={isSidebarOpen}
      width={isTooSmall ? '100%' : '75%'}
    >
      <div {...getRootProps()} style={{ height: '100%', overflowY: 'scroll' }}>
        <input {...getInputProps()} />
        {transferOrders && transferOrders.length > 0 ? (
          <>
            <List
              dataSource={transferOrders}
              renderItem={({ from, to, items, requestIds }) => {
                return (
                  <List.Item
                    key={`${from}_${to}`}
                    actions={[
                      <Create
                        onCreate={transferOrder => {
                          setEmailTransferOrders(emailTransferOrders => [
                            ...emailTransferOrders,
                            transferOrder,
                          ])
                        }}
                        transferOrder={{
                          from,
                          to,
                          items,
                          requestIds,
                          name: getStoreName(to),
                          storeClass: getStoreClass(to),
                          toSubsidiary: getSubsidiary(to),
                          fromSubsidiary: isStore(from)
                            ? getSubsidiary(from)
                            : warehouseType === 'fiege'
                            ? '1'
                            : '8',
                        }}
                      />,
                    ]}
                  >
                    <RecapTable
                      header={() =>
                        `From ${
                          isStore(from)
                            ? getStoreName(from)
                            : getWarehouseName(from)
                        } to ${getStoreName(to)}`
                      }
                      items={items}
                    />
                  </List.Item>
                )
              }}
            />
            <div
              style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                right: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                background: '#fff',
                textAlign: 'right',
                padding: '10px 16px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  position: 'relative',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <a href={href} download="transferorder.json">
                  Export
                </a>
                <div>
                  <Button
                    onClick={() => dispatch(closeSidebar())}
                    style={{ marginRight: 8 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      done || !token || emailTransferOrders.length === 0
                    }
                    onClick={() => setIsVisible(true)}
                    type="primary"
                  >
                    {done ? 'Email sent' : 'Send email'}
                  </Button>
                </div>
              </div>

              {emailTransferOrders && emailTransferOrders[0] && (
                <EmailForm
                  ref={formRef}
                  onCancel={handleCancel}
                  onSubmit={handleSubmit}
                  visible={isVisible && !done}
                  defaultEmailValue={getEmailFields({
                    transferOrders: emailTransferOrders.sort((a, b) =>
                      Math.sign(a.warehouseId - b.warehouseId),
                    ),
                    storeName: getStoreName(stores[0]),
                    when: getStoreDeliveryDay(stores[0]),
                    time: getStoreDeliveryTime(stores[0]),
                    pickUpDay: getStorePickUpDay(stores[0]),
                    pickUpTime: getStorePickUpTime(stores[0]),
                    shippingCurrier: getStoreShippingCurrier(stores[0]),
                    apparelAdditionalCare: getApparelAdditionalCare(stores[0]),
                    cc: `${getStoreEmail(stores[0])}${
                      isStore(emailTransferOrders[0].warehouseId)
                        ? ',ambra.zaffaroni@velasca.com,giorgia.galli@velasca.com'
                        : `,${getStoreCCEmail(stores[0])}`
                    }`,
                    to: isStore(emailTransferOrders[0].warehouseId)
                      ? getStoreEmail(emailTransferOrders[0].warehouseId)
                      : null,
                    storeFromName: getStoreName(
                      emailTransferOrders[0].warehouseId,
                    ),
                    isFromStore: isStore(emailTransferOrders[0].warehouseId),
                  })}
                />
              )}
            </div>
          </>
        ) : (
          <S.NoContent>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </S.NoContent>
        )}
        {isDragActive && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backdropFilter: 'blur(5px)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p style={{ fontSize: '1.625rem' }}>
              Rilascia il JSON transfer order qui
            </p>
          </div>
        )}
      </div>
    </S.Drawer>
  )
}

export default Sidebar
