import React from 'react'

import { Radio } from 'antd'

import { useDispatch } from 'react-redux'
import { setFilter } from '../../redux/filters/actions'

const WarehouseRadio = ({ disabled }) => {
  const dispatch = useDispatch()
  return (
    <Radio.Group
      defaultValue="fiege"
      buttonStyle="solid"
      disabled={disabled}
      onChange={e =>
        dispatch(setFilter({ key: 'warehouseType', value: e.target.value }))
      }
    >
      <Radio.Button value="fiege">FIEGE</Radio.Button>
      {/* <Radio.Button value="stores">Botteghe</Radio.Button> */}
      <Radio.Button value="fli">FLI</Radio.Button>
    </Radio.Group>
  )
}

export default WarehouseRadio
