import React, { useEffect, useCallback } from 'react'
import styledNormalize from 'styled-normalize'
import { createGlobalStyle } from 'styled-components'
import { Layout, Avatar, Menu, Dropdown } from 'antd'

import Can from './components/Can'

import { DownOutlined } from '@ant-design/icons'

import { BrowserRouter as Router, Link, Switch, Route } from 'react-router-dom'

import AvailabilitiesPage from './pages/Availabilities'
import RequestsPage from './pages/Requests'
import LabelsPage from './pages/Labels'

import Sidebar from './components/Sidebar'
import { ProductModelProvider } from './components/ProductModelProvider'
import { ProductNameProvider } from './components/ProductNameProvider'
import { ProductsProvider } from './components/ProductsProvider'

import { Provider as StateProvider } from 'react-redux'
import configStore from './redux/configStore'

import { useAuth0 } from './auth0-wrapper'
import config from './auth_config.json'
import { StoresProvider } from './components/StoresProvider'

import { StoreManagerRequestProvider } from './components/StoreManagerRequestProvider'

const { Header, Footer, Content } = Layout

const GlobalStyle = createGlobalStyle`
 ${styledNormalize}
 * {
   box-sizing: border-box;
 }
 html {
   min-height: 100%;
 }

 #root {
   min-height: 100%;
 }
`

function App() {
  const {
    isAuthenticated,
    loginWithRedirect,
    loading,
    user,
    getUserMetadata,
    logout,
  } = useAuth0()

  useEffect(() => {
    if (loading) return

    const fn = async () => {
      await loginWithRedirect()
    }

    if (!isAuthenticated) {
      fn()
    }
  }, [isAuthenticated, loginWithRedirect, loading])

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() =>
          logout({
            returnTo: process.env.REACT_APP_RETURN_TO,
            client_id: config.clientId,
          })
        }
      >
        Logout
      </Menu.Item>
    </Menu>
  )

  const userMetadata = getUserMetadata()

  const createStore = useCallback(() => {
    const store = configStore({
      filters: {
        store: [(userMetadata && userMetadata.storeId) || '20'],
        productModel: '3',
        searchType: 'productModel',
        warehouseType: 'fiege',
        showAll: false,
        showSublocations:
          user && user.permissions.indexOf('write:transferorders') > -1
            ? true
            : false,
        brands:
          user && user.permissions.indexOf('write:transferorders') > -1
            ? 'men'
            : 'all',
      },
    })

    return store
  }, [userMetadata, user])

  return (
    <Router>
      {isAuthenticated && !loading && user ? (
        <StateProvider store={createStore()}>
          <ProductModelProvider>
            <StoresProvider>
              <ProductNameProvider>
                <ProductsProvider
                  auto={user.permissions.indexOf('write:transferorders') > -1}
                  allWarehouses={
                    user.permissions.indexOf('write:transferorders') > -1
                  }
                >
                  <StoreManagerRequestProvider
                    fetchTransferOrderRequests={
                      user &&
                      user.permissions.indexOf('write:transferorders') > -1
                    }
                  >
                    <GlobalStyle />
                    <Layout style={{ minHeight: '100vh' }}>
                      <Header
                        style={{
                          position: 'fixed',
                          zIndex: 1000,
                          width: '100%',
                        }}
                      >
                        <div style={{ float: 'right' }}>
                          {isAuthenticated && !loading && (
                            <Can
                              permission="write:transferorders"
                              yes={() => {
                                return (
                                  <Dropdown
                                    overlay={menu}
                                    placement="bottomLeft"
                                  >
                                    <div
                                      style={{
                                        color: 'rgba(255, 255, 255, 0.65)',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <Avatar
                                        src={user.picture}
                                        style={{ marginRight: '1rem' }}
                                      />
                                      {user.email} <DownOutlined />
                                    </div>
                                  </Dropdown>
                                )
                              }}
                              no={() => {
                                return (
                                  <Dropdown
                                    overlay={menu}
                                    placement="bottomLeft"
                                  >
                                    <div
                                      style={{
                                        color: 'rgba(255, 255, 255, 0.65)',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <Avatar
                                        src={user.picture}
                                        style={{ marginRight: '1rem' }}
                                      />
                                      {user.email} <DownOutlined />
                                    </div>
                                  </Dropdown>
                                )
                              }}
                            />
                          )}
                        </div>
                        <Menu
                          theme="dark"
                          mode="horizontal"
                          style={{ lineHeight: '64px' }}
                        >
                          <Menu.Item key="1">
                            <Link to="/">Home</Link>
                          </Menu.Item>
                          <Menu.Item key="2">
                            <Link to="/requests">Requests</Link>
                          </Menu.Item>
                          <Menu.Item key="3">
                            <Link to="/labels">Labels</Link>
                          </Menu.Item>
                        </Menu>
                      </Header>
                      <Layout style={{ paddingTop: '64px' }}>
                        <Content
                          style={{
                            padding: '25px 25px 0',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Switch>
                            <Route path="/labels">
                              <LabelsPage />
                            </Route>
                            <Route path="/requests">
                              <RequestsPage />
                            </Route>
                            <Route path="/">
                              <AvailabilitiesPage />
                            </Route>
                          </Switch>
                        </Content>
                      </Layout>
                      <Footer>Velasca {new Date().getFullYear()}</Footer>
                      <Sidebar />
                    </Layout>
                  </StoreManagerRequestProvider>
                </ProductsProvider>
              </ProductNameProvider>
            </StoresProvider>
          </ProductModelProvider>
        </StateProvider>
      ) : null}
    </Router>
  )
}

export default App
